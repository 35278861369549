@font-face {
  font-family: "GT-Walsheim-Regular";
  src: url("./fonts/GT-Walsheim/GT-Walsheim-Regular-subset.woff2");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "GT-Walsheim-Bold";
  src: url("./fonts/GT-Walsheim/GT-Walsheim-Black-subset.woff2");
  font-style: medium;
  font-weight: 500;
  font-display: swap;
}

body {
  margin: 0;
  font-family: 'GT-Walsheim-Regular', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1{
  font-size:3em;
}
h2{
  font-size: 2em;
}
h3{
  font-size: 1.3em;
}
h1,h2,h3,h4{
  font-family: 'GT-Walsheim-Bold', 'Helvetica Neue', sans-serif;
}
h5,h6{
  font-family: 'GT-Walsheim-Regular', 'Helvetica Neue', sans-serif;
  font-weight: bold;
}