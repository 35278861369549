:root {
  --large-h1: 95px;
  --second-color: #ff7;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}
h1 {
  font-size: var(--large-h1);
}
li {
  list-style-type: square;
}

.header,
.footer {
  height: 41px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: black;
  background-color: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(5px);
}
.header h4 {
  font-size: 16px;
}
@media (max-width: 600px) {
  .header h4{
    font-size: 11px;
  }
}

.footer {
  justify-content: center;
  color: #f4fb56;
  position: relative;
}
.footer-slide-title {
  text-transform: uppercase;
  padding: 0 10px;
  position: absolute;
  right: 10px;
  color: #fefefe30;
  font-size: 12px;
  font-weight: 900;
}
.footer-question {
  pointer-events: auto;
  position: absolute;
  left: 10px;
}
.footer-question a {
  color: #f4fb56;
}
.footer-question a:hover {
  color: #f4fb5680;
}
.footer-return-link {
  pointer-events: auto;
  color: #f4fb56;
  font-size: 20px;
  text-transform: uppercase;
}
.footer-radios-box {
  display: flex;
}
.footer-radios {
  width: 25px;
  border: 4px solid #f4fb56;
  content: "";
  position: relative;
  height: 25px;
  border-radius: 50%;
  margin-right: 4px;
  margin-top: 0px;
  pointer-events: auto;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 0px;
  padding-bottom: 0px;
}
.footer-radios .progress-letters {
  color: #f4fb56;
  font-size: 12px;
  cursor: inherit;
  text-transform: uppercase;
}
.presentation-array {
  height: 24px;
  display: flex;
  align-items: center;
}
.presentation-array a {
  pointer-events: auto;
  color: #f4fb56;
  position: relative;
  top: 0px;
  left: 20px;
  cursor: pointer;
}
.presentation-array::before {
  content: "";
  border: thin solid #f4fb56;
  margin-left: 20px;
  position: relative;
  height: 24px;
  opacity: 0.4;
}
.presentation-array a:hover {
  color: #f4fb5680;
}
.slide-container {
  display: flex;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
}
.container-large {
  width: initial;
  min-width: 800px;
  display: flex;
  justify-content: center;
  position: relative;
}
.large-image {
  border-radius: 12px;
  overflow: hidden;
  height: 765px;
  box-shadow: 0 0 3px black;
  width: 100%;
  min-width: 1024px;
  /* striped background */
  background: repeating-linear-gradient(
    45deg,
    #494d4680,
    #494d4680 40px,
    #494d466c 40px,
    #494d466c 80px
  );
}
.large-image-loader {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.circle-loader {
  animation: spinner 0.6s linear infinite;
  filter: drop-shadow(0px 0px 4px white);
}
.right-sidebar-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  width: 25%;
  max-width: 420px;
  height: 765px;
}
.sidebar-image-container {
  position: relative;
  height: 250px;
  overflow: hidden;
  border-radius: 12px;
  box-shadow: 0 0 6px black;
  margin-bottom: 8px;
  width: 400px;
  transition: transform 200ms, box-shadow 1000ms;
  background: repeating-linear-gradient(
    45deg,
    #494d4680,
    #494d4680 40px,
    #494d466c 40px,
    #494d466c 80px
  );
}
.sidebar-image-container:last-child {
  margin-bottom: 0;
}

.sidebar-image-name {
  position: absolute;
  bottom: 10px;
  font-size: 16px;
  left: 50px;
  font-weight: bold;
  z-index: 0;
  text-shadow: 0 0 2px #414141;
  color: #fefefe;
}
.added {
  position: absolute;
  top: 15px;
  right: -29px;
  width: 100px;
  height: 16px;
  transform: rotate(
45deg);
  color: #f4fb56;
  font-size: 13px;
  background: repeating-linear-gradient( 
45deg, #494d4680, #494d4680 4px, #494d466c 4px, #494d466c 8px );
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.sidebar-image-name::after {
  position: absolute;
  width: 400px;
  height: 42px;
  content: "";
  z-index: -1;
  left: -10px;
  bottom: -13px;
  background-color: rgba(255, 255, 255, 0.15);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(8px);
  border-top-left-radius: 12px;
}
.sidebar-title {
  height: 75px;
  display: flex;
  margin-bottom: 10px;
  width: 130%;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(5px);
  z-index: 10;
  border-radius: 10px;
  align-items: center;
  font-weight: 900;
  margin-left: -40px;
}
.large-image-radios-box {
  display: flex;
}
.large-image-radios {
  width: 25px;
  border: 4px solid #f4fb56;
  content: "";
  position: relative;
  height: 25px;
  border-radius: 50%;
  margin-right: 4px;
  margin-top: 0px;
  pointer-events: auto;
}
.large-info-content {
  position: absolute;
  bottom: 30px;
  left: -40px;
  padding: 10px 20px;
  background-color: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(5px);
  border-radius: 12px;
  min-width: 380px;
}
.large-info-content h3 {
  color: #313131;
  text-shadow: 0 0 3px white;
}
.large-info-content h4 {
  text-transform: uppercase;
  font-size: 16px;
  color: #FEFEFE;
  padding-left: 20px;
  text-shadow: 0 0 2px rgba(0, 0, 0, 0.8);
}
.large-info-content .price {
  position: absolute;
  bottom: -10px;
  right: -20px;
  background: #f4fb5640;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 20px;
  font-weight: bold;
  color: #525252;
}
.no-to-waste {
  font-size: 92px;
  background: -webkit-linear-gradient(#eee, #f4fb56);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.centered-images {
  position: relative;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
}

.kind {
    position: absolute;
    z-index: 1;
    transform: translate(-50%, -50%);
}
.kind-leather-badge {
  width: 80px;
  height: 80px;
  border-radius: 40px;
  position: absolute;
  left: -30px;
  top: 70px;
  border: #183c2d solid thin;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 60px;
  font-weight: bold;
  background-color: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(5px);
  transform: rotate(-7deg);
}
.qr-code {
  width: 80px;
  height: 80px;
  border-radius: 40px;
  position: absolute;
  left: -30px;
  bottom: 170px;
  border: #183c2d solid thin;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 60px;
  font-weight: bold;
  background-color: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(5px);
  transform: rotate(-7deg);
}
.qr-code img {
  width: 80px;
  height: 80px;
}

.page-array {
  display: flex;
  flex-wrap: wrap;
  width: 90%;
  justify-content: center;
}
@media (max-width: 1380px) {
  .page-array {
    width: 110%;
  }
  .large-image {
    width: 100%;
  }
}
.title {
  height: 75px;
  display: flex;
  margin-bottom: 10px;
  width: 30%;
  padding: 10px 20px;
  background-color: rgba(0, 0, 0, 0.15);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  z-index: 1;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  align-items: center;
  font-weight: 900;
  margin-left: 0px;
  position: absolute;
  left: 0px;
  top: 55px;
  justify-content: flex-end;
  flex-direction: column;
  align-items: flex-end;
}
.feature-image {
  margin: 10px;
  overflow: hidden;
  border-radius: 12px;
  height: 300px;
  display: flex;
  align-items: normal;
  justify-content: center;
  position: relative;
  width: 400px;
}
.feature-image-title {
  position: absolute;
  bottom: 10px;
  text-transform: uppercase;
  left: 50px;
  font-size: 16px;
  z-index: 0;
  font-weight: bold;
  text-shadow: 0 0 2px #414141;
}
.feature-image-title::after {
  position: absolute;
  width: 360px;
  height: 42px;
  content: "";
  z-index: -1;
  left: -10px;
  bottom: -13px;
  background-color: rgba(255, 255, 255, 0.15);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(8px);
  border-top-left-radius: 12px;
}
.modal-background {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.3);
  top: 0px;
  left: 0px;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-window {
  background-color: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(5px);
  border-radius: 12px;
  width: 1100px;
  height: 800px;
  display: flex;
  flex-direction: column;
  z-index: 2;
  padding: 20px;
  position: relative;
}
.modal-window-image {
  width: 100%;
  height: 750px;
  border-radius: 8px;
  overflow: hidden;
  margin: 10px 0;
  display: flex;
  align-items: flex-start;
  position: relative;
  background: repeating-linear-gradient(
    45deg,
    #494d4680,
    #494d4680 40px,
    #494d466c 40px,
    #494d466c 80px
  );
}
.modal-window-image img {
  width: inherit;
}
.modal-window-image .large-image-radios-box {
  position: absolute;
  bottom: 10px;
  right: 10px;
}
.modal-close {
  position: absolute;
  right: -35px;
  top: 0px;
  cursor: pointer;
}
.modal-next {
  position: absolute;
  right: -45px;
  top: 50%;
  cursor: pointer;
  width: 40px;
  height: 80px;
  background: rgba(0, 0, 0, 0.5);
  color: rgba(255, 255, 255, 0.8);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
}
.modal-close:hover {
  color: #414141;
}

/* Directions container */

a {
  color: white;
  text-decoration: none;
}
a:hover {
  color: lightgray;
}

.info-container {
  display: flex;
  flex-direction: column;
  position: absolute;
  padding: 50px 10px;
  height: 100%;
  width: 100%;
  font-size: 20px;
  background-image: url('../images/flatSwatch.jpg');
}
.info-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 95%;
  position: relative;
}
tr {
  font-size: 12px;
}
.info-body-columns {
  display: flex;
  width: 90%;
  margin: 1em;
}
.column-one {
  width: 20%;
}
.column-two {
  padding: 0 20px;
  width: inherit;
}
.column-three {
  width: 320px;
}
.arrows {
  display: flex;
  margin-bottom: 10px;
}
.arrows div {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.keys {
  height: 30px;
  width: 60px;
  border: 3px solid white;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  margin: 3px;
}
.keys-fill {
  border: 3px solid white;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff40;
}
.keyboard-directions {
  width: 300px;
  border: 3px solid white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-radius: 10px;
}
.keyboard-directions h2 {
  font-size: 1em;
  margin-bottom: 0.5em;
}
.keyboard-directions h4 {
  font-size: 0.8em;
  margin-bottom: 0.5em;
  text-align: center;
}
.keyboard-direction-screen {
  width: 280px;
  height: 5em;
  border: 3px solid white;
  justify-content: center;
  display: flex;
  align-items: center;
  margin-top: 20px;
  overflow: hidden;
}
.info-container p{
  margin: 20px;
}
.directions-slider{
  width:300px;
  height:500px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.directions-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 5%;
}
.search input[type="text"] {
  border: none;
  width: 100%;
  height: 40px;
  padding-left: 10px;
  border-radius: 10px;
  background: #fefefe;
  font-size: 20px;
  font-weight: 400;
  outline: none;
  text-align: center;
  text-transform: uppercase;
}
.search-results {
  font-size: 15px;
  text-transform: capitalize;
  display: flex;
  cursor: pointer;
}
.search-results-item:hover{
  color: black;
}
tbody h5{
  font-weight: 100;
}
table{
  border: thin solid;
    padding: 3px;
}
tr:hover{
  color:black;
  cursor: pointer;
}

/* Info Pages */

.info-slide-left{
    width: 40%;
    height: 65vh;
    padding: 10px;
    padding-top: 140px;
}
.info-slide-left h2{
    margin-bottom: 40px;
    text-align: left;
    margin-left: 40px;
}
.info-slide-left h5{
    width: 90%;
    text-align: left;
    padding-left: 80px;
}
.info-slide-right{
    width:60%;
    border-left: 1px solid #FEFEFE;
    position: relative;
    height: 65vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.info-slide-right .transition{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.info-slide-right .transition .overlap{
  position: absolute;
}
.info-slide-right img{
    max-width: 40vw;
}
.kind-leather-logo{
    position: absolute;
    top: 70px;
    left: 30px;
    width: 200px;
}
.skin-text{
  position: absolute;
  z-index: 2;
  top:40%;
}
.skin-text h3{
  color:#f4fb56
}
.skin-message{
  position: absolute;
  bottom:10px;
}