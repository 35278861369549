@media (max-width: 600px) {
    .mobile-info-container h6{
        font-size: 10px;
    }
  }
.mobile-container{
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}
.mobile-image-container{
    height: 42%;
    width: 100%;
    overflow: hidden;
    margin-top: 35px;
    position: relative;
    
}
.vendor{
    position: absolute;
    top: 10px;
    left: 6px;
    text-transform: uppercase;
    font-size: 20px;
    text-shadow: 0 0 2px black;
    color: #FEFEFE;
    z-index: 2;
}
.mobile-image-container .large-image-radios-box{
    position: absolute;
    width: 100%;
    bottom: 20px;
    display: flex;
    justify-content: center;
    z-index: 2;
}
.mobile-info-container{
    height: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px;
    text-align: center;
    text-transform: uppercase;
}
.mobile-info-container h5{
    text-transform: uppercase;
    font-size:14px;
}
.mobile-info-container hr{
    width: 80%;
    border: #414141 thin dashed;
    margin:2px;
}
.large-image-container{
    border-bottom: 3px solid black;
    border-bottom-left-radius: 10px;
    overflow: hidden;
    border-bottom-right-radius: 10px;
    height: 390px;
    background: repeating-linear-gradient(
    45deg,
    #494d4699,
    #494d4699 40px,
    #494d466c 40px,
    #494d466c 80px
  );
}
.scrollable-image-container img{
    height:390px;
    
}
.mobile-error{
    width: 100%;
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    padding: 0 20px;
}
.save-selection-button{
    width: 100%;
    position: absolute;
    bottom: 40px;
    height: 50px;
    border: none;
    background: #f4fb56;
    left:0;
    font-size: 14px;
}
.save-selection-button-stored{
    width: 100%;
    position: absolute;
    bottom: 40px;
    height: 18px;
    border: none;
    background: #f4fb56;
    color: black;
    padding: 1px;
}

.save-selection-button h5 {
    border: thin solid black;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    text-transform: uppercase;
}

.footer-items{
    display: flex;
    justify-content: center;
    border: thin solid;
    height: 28px;
    align-items: center;
    border-radius: 4px;
    padding: 0 10px;
    text-transform: uppercase;
    pointer-events: all;
}
.mobile-pop{
    position: absolute;
    color: black;
    z-index: 7;
    top: 0px;
    left: 0;
    width: 100%;
    padding: 45px 15px;
    box-sizing: border-box;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);

}
.mobile-pop-close{
    position: absolute;
    top: 40px;
    right: 10px;
    z-index: 10;
}

.mobile-overlay-items{
    height: 45px;
    border: thin solid black;
    border-radius: 3px;
    display: flex;
    padding: 5px;
    align-items: center;
    color: black;
    margin: 2px 0;
    text-transform: uppercase;
}
.mobile-overlay-items:active{
    border: thin solid #f4fb56;
    color: #f4fb56;
    background-color: #414141;
}